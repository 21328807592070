import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
//import logo_light from '../images/logo_light.png';
//import logo_dark from '../images/logo_dark.png';
import logo from '../images/logo.png';
import { useSelector, useDispatch  } from 'react-redux';

import {tuggleMode} from '../store'
const Header = () =>{

    const color = useSelector((state) => state.counter.mode);
    const dispatch = useDispatch();
    return(
        <Navbar className="nave-bar" bg={color} expand="md" data-bs-theme={color}>
            <Container>
                <a href="/">
                    { color === "light" ? (<img
                        alt="Samco"
                        src={logo}
                        width="160"
                        height="80"
                        className="d-inline-block align-top"
                        />) :
                    (<img
                        alt="Samco"
                        src={logo}
                        width="160"
                        height="80"
                        className="d-inline-block align-top"
                        />)}
                </a>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav  className="me-auto d-flex"></Nav>
                    <Nav className="header-links" >
                        <h5><Nav.Link href="/aboutUs">About us</Nav.Link></h5>
                        <h5><Nav.Link href="/services">Services</Nav.Link></h5>
                        <h5><Nav.Link href="/projects">Projects</Nav.Link></h5>
                        <h5><Nav.Link href="/contactUs"> quality </Nav.Link></h5>
                        <h5><Nav.Link href="/safety">Safety</Nav.Link></h5>
                        <Nav.Link className="color-button" onClick={()=>dispatch(tuggleMode())} >
                            <svg width="30" height="30" fill="currentColor" class="bi bi-moon-stars" viewBox="0 0 16 16">
                                <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z"/>
                                <path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z"/>
                            </svg>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
export default Header